<template>
  <!--  <v-simple-table>-->
  <!--    <template v-slot:default>-->
  <!--      <thead>-->
  <!--        <tr>-->
  <!--          <th class="text-uppercase">ID</th>-->
  <!--          <th class="text-center text-uppercase">imagePath</th>-->
  <!--          <th class="text-center text-uppercase">imageThumbnailPath</th>-->
  <!--          <th class="text-center text-uppercase">price</th>-->
  <!--          <th class="text-center text-uppercase">hasBeenUnlocked</th>-->
  <!--        </tr>-->
  <!--      </thead>-->
  <!--      <tbody>-->
  <!--        <tr v-for="item in wallpapers.data" :key="item.dessert">-->
  <!--          <td>{{ item.wallpaperId }}</td>-->
  <!--          <td class="text-center">-->
  <!--            {{ item.imagePath }}-->
  <!--          </td>-->
  <!--          <td class="text-center">-->
  <!--            {{ item.imageThumbnailPath }}-->
  <!--          </td>-->
  <!--          <td class="text-center">-->
  <!--            {{ item.price }}-->
  <!--          </td>-->
  <!--          <td class="text-center">-->
  <!--            {{ item.hasBeenUnlocked }}-->
  <!--          </td>-->
  <!--        </tr>-->
  <!--      </tbody>-->
  <!--    </template>-->
  <!--  </v-simple-table>-->
  <!--  <v-row>-->
  <!--    <v-col md="2" v-for="item in wallpapers.data" :key="item.wallpaperId" sm="6" cols="12">-->
  <!--      <v-card color="">-->
  <!--        <v-img :src="baseurl + item.imageThumbnailPath"  />-->
  <!--&lt;!&ndash;        <v-card-title> {{ item.price }}</v-card-title>&ndash;&gt;-->
  <!--        <v-card-text class="text&#45;&#45;primary text-base ml-0 pl-0 mt-2 mb-0">-->
  <!--          <span>Price:</span> <span class="font-weight-bold">{{ item.price }}</span>-->
  <!--        </v-card-text>-->
  <!--        <v-card-actions class="dense pa-0 px-1 mt-0 row">-->
  <!--          <v-btn-->
  <!--            color="primary"-->
  <!--            class="mt-0 col-md-6 col-sm-12"-->
  <!--            dark-->
  <!--            outlined-->
  <!--          >-->
  <!--            Edit-->
  <!--          </v-btn>-->
  <!--          <v-btn-->
  <!--            dark-->
  <!--            class="mt-0 col-md-6 col-sm-12 ml-0"-->
  <!--            outlined-->
  <!--            color="error"-->
  <!--          >-->
  <!--            Delete-->
  <!--          </v-btn>-->
  <!--        </v-card-actions>-->
  <!--        &lt;!&ndash;        <v-card-text> Cancun is back, </v-card-text>&ndash;&gt;-->
  <!--      </v-card>-->
  <!--    </v-col>-->
  <!--  </v-row>-->

  <v-container
    fluid
    grid-list-xl
  >
    <v-layout
      wrap
      justify-space-around
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="mb-4"
      ></v-progress-circular>
      <v-flex
        v-else
        v-for="(image, i) in wallpapers"
        :key="i"
      >
        <v-card
          elevation="3"
          width="150px"
          class="overflow-hidden"
        >
          <div class="image-container" style="height: 100%; width: 100%; overflow: hidden">
            <v-img
              :src="image.imagePath"
              height="270"
              class="cursor-pointer wallpaper"
              style="z-index: 1 !important"
              @click="index = i"
              lazy-src="@/assets/images/lazy.jpg"
            ></v-img>
          </div>
          <v-card-text
            class="pl-2 pt-1 pb-0"
            style="z-index: 100 !important"
          >
            <span>{{ $t('price') }}: </span> <b>{{ image.price }}</b>
          </v-card-text>
          <vue-gallery-slideshow
            :images="fullImages"
            :index="index"
            @close="index = null"
          ></vue-gallery-slideshow>
          <v-card-actions class="pb-2">
            <v-btn icon @click="edit(image)">
              <v-icon>{{ icons.mdiPencilCircleOutline }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="deleteImage(image)">
              <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  mdiPencilCircleOutline, mdiDeleteCircleOutline, mdiGithub, mdiMagnify, mdiMenu,
} from '@mdi/js'
import VueGallerySlideshow from 'vue-gallery-slideshow'
import { API_URL } from '@/_plugins/axios'

export default {
  components: {
    VueGallerySlideshow,
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilCircleOutline,
        mdiGithub,
        mdiDeleteCircleOutline,
        mdiMenu,
      },
    }
  },
  data() {
    return {
      wallpapers: [],
      constants: [],
      fullImages: [],
      index: null,
      loading: false,
    }
  },
  mounted() {
    this.fetchWallpapers()
    window.Bus.$on('addedWallpaper', () => {
      this.fetchWallpapers()
    })
  },
  methods: {
    fetchWallpapers() {
      this.loading = true
      this.fullImages = []
      API_URL.get('wallpapers').then(res => {
        this.wallpapers = res.data
        res.data.forEach(item => {
          this.fullImages.push(item.imagePath)
        })
        this.loading = false
      })
    },
    edit(image) {
      this.$emit('edit', image)
    },
    deleteImage(image) {
      this.$emit('delete', image)
    },
  },
}
</script>
<style lang="scss">
.flex {
  flex-grow: 0;
}

.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  div {
    width: 150px;
  }
}

.wallpaper {
  transition: 300ms all ease-in-out;
  max-height: 100% !important;
  object-fit: inherit;
  overflow: hidden;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
