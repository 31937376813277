<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card class="px-2">
        <v-card-title class="flex justify-space-between">
          <p>
            {{ $t('wallpapers') }}
          </p>
          <v-btn color="primary" @click.stop="emitAddWallpaper">
            <v-icon dark class="pr-2">
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add_wallpaper') }}
          </v-btn>
        </v-card-title>
        <wallpaper @edit="emitEditWallpaper" @delete="emittedDeleteWallpaper"></wallpaper>
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
              <v-icon class="mr-2">
                {{ icons.mdiAlertOutline }}
              </v-icon>
              {{ $t('confirmation') }}
            </v-card-title>

            <v-card-text>
              {{ $t('delete_confirmation') }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :disabled="loading" :loading="loading" text @click="deleteWallpaper">
                {{ $t('confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPlusCircle, mdiAlertOutline } from '@mdi/js'
import Wallpaper from './components/Wallpaper.vue'
import { API_URL } from '@/_plugins/axios'

export default {
  components: {
    Wallpaper,
  },
  setup() {
    const dialog = false
    const deleteItem = null
    const loading = false

    return {
      loading,
      dialog,
      deleteItem,
      icons: {
        mdiPlusCircle,
        mdiAlertOutline,
      },
    }
  },
  methods: {
    emitAddWallpaper() {
      window.Bus.$emit('open-add-wallpaper')
    },
    emitEditWallpaper(item) {
      window.Bus.$emit('open-add-wallpaper-edit', item)
    },
    emittedDeleteWallpaper(item) {
      this.deleteItem = item.wallpaperId
      this.dialog = true
      console.log(item)
      // eslint-disable-next-line vue/custom-event-name-casing
      // this.$parent.$parent.$parent.openAddWallpaper(item)
    },
    deleteWallpaper() {
      this.loading = true
      API_URL.delete(`wallpapers/${this.deleteItem}`).then(() => {
        this.loading = false
        this.dialog = false
        // eslint-disable-next-line vue/custom-event-name-casing
        window.Bus.$emit('addedWallpaper')
        this.$toast.success('Success')
      }).catch(() => {
        this.loading = false
        this.dialog = false
      })
    },
  },
}
</script>
